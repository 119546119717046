@import '../../styles/variable.scss';

$mSize: 60px;
.main {
  position: absolute;
  top: 50%;
  color: $PRIMARY_COLOR;
}
.medium {
  height: $mSize;
  width: $mSize;
  z-index: 1;
  @extend .main;
  left: calc(50% - 30px);
}

.button-spinner {
  height: $LARGE_FONT - 2;
  width: $LARGE_FONT - 2;
  margin-right: 2px;
}
