// ------colors-------
$WHITE_COLOR: #fff;
$RED_COLOR: #ff0000;
$BLACK_SHADE: #3a3f52;
$BLACK: #000000;
$PRIMARY_COLOR: #ee2649;
$SECONDARY_COLOR: #666666;
$GREEN_COLOR: #1eab1edb;

// ------colors-------

// ------padding------
$APP_PADDING: 30px;
$SECONDARY_PADDING: 20px;
$MODAL_PADDING: 10px;
// ------padding------

// ------font size------
$MEDIUM_FONT: 14px;
$LARGE_FONT: 20px;
$EXTRA_LARGE_FONT: 24px;
// ------font size------

// ------shadow------
$BOX_SHADOW: 0 4px 8px 0 $SECONDARY_COLOR;
// ------shadow------

// ------scrollbar------
$SCROLL_HEIGHT: 6px;
$SCROLL_WIDTH: 6px;
$SCROLL_COLOR: #6c757d;
$SCROLL_TRACK_COLOR: #f5f5f5;
$SCROLL_SHADOW: inset 0px 0px 6px 6px $SCROLL_COLOR;
// ------scrollbar------

// -------Text-------
$DEFAULT_TEXT_COLOR: $SECONDARY_COLOR;
$DEFAULT_TEXT_FONT: $MEDIUM_FONT;

$TITLE_TEXT_COLOR: $SECONDARY_COLOR;
$TITLE_TEXT_FONT: $LARGE_FONT;
$TITLE_FONT_WEIGHT: 500;
// -------Text-------

// --------Misc------
$NAVBAR_HEIGHT: 70px;
$BORDER_RADIUS: 4px;

// ===================
// Export to use in JS
// ===================
:export {
  WHITE_COLOR: $WHITE_COLOR;
  TITLE_FONT_WEIGHT: $TITLE_FONT_WEIGHT;
  EXTRA_LARGE_FONT: $EXTRA_LARGE_FONT;
  PRIMARY_COLOR: $PRIMARY_COLOR;
  GREEN_COLOR: $GREEN_COLOR;
  BLACK: $BLACK;
}
